import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Input, Modal, Space, Spin, Switch, message } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import CommonTable from 'components/CommonTable';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { EOriderType, EProviderListMappingStatusValue, EProviderMappingStatusValue, IBPMappingItem, IGetProviderMappingDataInput, IProviderMappingDataResp } from 'types/operation';
import { getProviderNameDisplay } from 'utils/provider';
import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import useGetProviderMappingData from 'hooks/helloklarityOps/useGetProviderMappingData';
import OpsProviderMappingListStatusTag from 'components/OpsProviderMappingListStatusTag';
import { exportEHRProviderList, getHelloKlairtyProfileLink, getPrivateFilePreview, syncEhr } from 'api/operation';
import useGetSettingData from 'hooks/useGetSettingData';
import { EnumFields } from 'types/enumerationData';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';
import { prereleaseOriginReplacement } from 'utils/helloklarity';
import { PRE_RELEASE_ORIGIN } from 'constants/common';
import MulitProfileLinkModal from '../MulitProfileLinkModal';
import { downloadFileWithBlob } from 'utils/file';
import { useRequest } from 'ahooks';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import useGetProgramsList from 'hooks/ProgramsOps/useGetProgramsList';
import { IKlarityProgram } from 'types/programs';
import { updateProgramDisplayStatus } from 'api/operation/programs';

interface IProps {
    showMappingModal: () => void;
    hideMappingConfirmModal: () => void;
    showMappingConfirmModal: () => void;
    showSlugModal: () => void;
    showTitleModal: () => void;
    showPatientRatingModal: () => void;
    handleSetCurrentRow: (id: IBPMappingItem | null) => void;
    forceReload: Record<any, any>;
    handleListingStatusChange: (id: number, status: EProviderListMappingStatusValue) => void;
    findProviderByEmail: (email: string, type: number) => Promise<any>;
}

const HelloKlarityTable = ({
    showMappingModal,
    showSlugModal,
    hideMappingConfirmModal,
    showMappingConfirmModal,
    showPatientRatingModal,
    handleSetCurrentRow,
    handleListingStatusChange,
    forceReload,
    findProviderByEmail,
}: IProps) => {
    const navigate = useNavigate();
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');
    const [providerListingStatus] = useGetSettingData(EnumFields.PROVIDER_MAPPING_LIST_STATUS, true) as (CheckboxOptionType[])[];
    const [providerMappingStatusSettingData] = useGetSettingData(EnumFields.PROVIDER_MAPPING_STATUS, true) as (CheckboxOptionType[])[];
    const providerMappingStatus = providerMappingStatusSettingData?.filter((option) => option.value !== EProviderMappingStatusValue.AIR_TABLE_MAPPING);
    const [patientRatingStatus] = useGetSettingData(EnumFields.PROVIDER_PATIENT_RATING, true) as (CheckboxOptionType[])[];
    const [pageLoading, setPageLoading] = useState(false);
    const [orderField, setOrderKey] = useState<string>('');
    const [showProgramModal, setShowProgramModal] = useState(false);
    const [programList, programLoading] = useGetProgramsList();

    const programOptions = useMemo(() => {
        return programList ? programList.map((program) => ({
            text: program.programName,
            value: program.id,
        })) : [];
    }, [programList]);

    const ehrStatus = useRef<CheckboxOptionType[]>([
        {
            label: 'Complete',
            value: true,
        },
        {
            label: 'Incomplete',
            value: false,
        },
    ]);

    const bookingLinkStatus = useRef<CheckboxOptionType[]>([
        {
            label: 'None',
            value: 0,
        },
        {
            label: '1 Package',
            value: 1,
        },
        {
            label: '2 Packages',
            value: 2,
        },
    ]);

    const providerMicStatus = useMemo(() => [
        {
            label: 'Effective',
            value: 'Effective',
        },
        {
            label: 'None',
            value: 'None',
        },
        {
            label: 'Expired',
            value: 'Expired',
        },
    ], []);

    // 先保留，防止后面改成需要控制edit权限
    const editPermission = true;
    const ratingPermission = useMemo(() => !!(access && access[PermissionModuleKeys.KLARITY_PROVIDER_KIWI_RATING]), [access]);

    // string filter
    const [nameFilter, setNameFilter] = useState('');
    const [slugFilter, setSlugFilter] = useState('');
    const [uidFilter, setUidFilter] = useState('');

    // pagination
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    // order
    const [orderType, setOrderType] = useState<EOriderType | undefined>(EOriderType.DESC);

    // profile status filter
    const [listingStatusIndeterminate, setListingStatusIndeterminate] = useState(false);
    const [listingStatusCheckAll, setListingStatusCheckAll] = useState(false);
    const [listingStatusList, setListingStatusList] = useState<Array<CheckboxValueType>>([]);

    // mapping status filter
    const [mappingStatusIndeterminate, setMappingStatusIndeterminate] = useState(false);
    const [mappingStatusCheckAll, setMappingStatusCheckAll] = useState(false);
    const [mappingStatusList, setMappingStatusList] = useState<Array<CheckboxValueType>>([]);

    // booking link filter
    const [bookingLinkIndeterminate, setBookingLinkIndeterminate] = useState(false);
    const [bookingLinkCheckAll, setBookingLinkCheckAll] = useState(false);
    const [bookingLinkList, setBookingLinkList] = useState<Array<CheckboxValueType>>([]);

    // patient rating filter
    const [patientRatingIndeterminate, setPatientRatingIndeterminate] = useState(false);
    const [patientRatingCheckAll, setPatientRatingCheckAll] = useState(false);
    const [patientRatingList, setPatientRatingList] = useState<Array<CheckboxValueType>>([]);

    // provider mis status filter
    const [micStatusIndeterminate, setMicStatusIndeterminate] = useState(false);
    const [micStatusCheckAll, setMicStatusCheckAll] = useState(false);
    const [micStatusList, setMicStatusList] = useState<Array<CheckboxValueType>>([]);

    const [showProfileLinkModal, setShowProfileLinkModal] = useState(false);
    const [currentProfileLinks, setCurrentProfileLinks] = useState([]);

    const [ehrStatusFilter, setEhrStatusFilter] = useState<Array<CheckboxValueType>>([]);

    const [programIdListFilter, setProgramIdListFilter] = useState<Array<number>>([]);

    const [data = {}, tableLoading, tableError, bpRefetch] = useGetProviderMappingData();
    const [syncingEhrId, setSyncingEhrId] = React.useState<number>();

    const [currentPrograms, setCurrentPrograms] = useState<IKlarityProgram[]>([]);
    const [currentProviderId, setCurrentProviderId] = useState<number>();
    const [displayLoading, setDisplayLoading] = useState(false);

    const { records, total = 0 } = data as IProviderMappingDataResp;

    useEffect(() => {
        if (tableError) {
            message.error(tableError);
        }
    }, [tableError]);

    const handleTableChange = useCallback((paginationData?: TablePaginationConfig, filtersData?: any, sorter?: any, action?: any) => {
        setCurrent(paginationData?.current!);
        setPageSize(paginationData?.pageSize!);

        if (action?.action === 'sort') {
            const orderKey = sorter.order === 'ascend' ? EOriderType.ASC : EOriderType.DESC;

            setOrderKey(sorter.field === 'firstLaunchAt' ? 'first_launch_at' : sorter.field);
            setOrderType(orderKey);
        }

        setProgramIdListFilter(filtersData.programList)
    }, []);

    const handleRefetch = useCallback(() => {
        const params: IGetProviderMappingDataInput = {
            bookLink: bookingLinkList as number[],
            current,
            // ehrProviderId,
            intakeqProviderId: '',
            listingStatus: listingStatusList as string[],
            mappingStatus: mappingStatusList as string[],
            micStatus: micStatusList as string[],
            name: nameFilter,
            orderKey: orderField,
            orderType,
            patientRating: patientRatingList as number[],
            providerNameSlug: slugFilter,
            size: pageSize,
            title: '',
            type: 1,
            uidPublic: uidFilter,
            programIdList: programIdListFilter,
        };
        if (ehrStatusFilter.length === 1) {
            params.ehrSetUpStatus = [ehrStatusFilter[0] as boolean];
        }

        bpRefetch(params);
    }, [forceReload, bookingLinkList, bpRefetch, current, handleTableChange, listingStatusList, mappingStatusList, nameFilter, orderType, pageSize, patientRatingList, slugFilter, micStatusList, ehrStatusFilter, uidFilter, orderField, programIdListFilter]);

    useEffect(() => {
        handleRefetch();
        // }, [forceReload, bookingLinkList, bpRefetch, current, handleTableChange, listingStatusList, mappingStatusList, nameFilter, orderType, pageSize, patientRatingList, slugFilter, micStatusList, ehrStatusFilter, uidFilter, orderField, programIdListFilter]);
    }, [handleRefetch]);

    // profile status filter
    const handleListingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && providerListingStatus ? providerListingStatus.map((option: CheckboxOptionType) => option.value) : [];

        setListingStatusList(checkedListData);
        setListingStatusIndeterminate(false);
        setListingStatusCheckAll(e.target.checked);
        setCurrent(1);
    }, [providerListingStatus]);

    const handleListingStatusFilter = useCallback((statusList: CheckboxValueType[]) => {
        setListingStatusList(statusList);
        setListingStatusIndeterminate(!!statusList.length && !!providerListingStatus && statusList.length < providerListingStatus.length);
        setListingStatusCheckAll(statusList.length === providerListingStatus?.length);
        setCurrent(1);
    }, [providerListingStatus]);

    // mapping status filter
    const handleMappingStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && providerMappingStatus ? providerMappingStatus.map((option: CheckboxOptionType) => option.value) : [];

        setMappingStatusList(checkedListData);
        setMappingStatusIndeterminate(false);
        setMappingStatusCheckAll(e.target.checked);

        setCurrent(1);
    }, [providerMappingStatus]);

    const handleMappingStatusFilter = useCallback((statusList: CheckboxValueType[]) => {
        setMappingStatusList(statusList);
        setMappingStatusIndeterminate(!!statusList.length && !!providerMappingStatus && statusList.length < providerMappingStatus.length);
        setMappingStatusCheckAll(statusList.length === providerMappingStatus?.length);

        setCurrent(1);
    }, [providerMappingStatus]);

    // booking link filter
    const handleBookingLinkFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && bookingLinkStatus.current ? bookingLinkStatus.current.map((option: CheckboxOptionType) => option.value) : [];

        setBookingLinkList(checkedListData);
        setBookingLinkIndeterminate(false);
        setBookingLinkCheckAll(e.target.checked);

        setCurrent(1);
    }, []);

    const handleBookingLinkFilter = useCallback((statusList: CheckboxValueType[]) => {
        setBookingLinkList(statusList);
        setBookingLinkIndeterminate(!!statusList.length && !!bookingLinkStatus.current && statusList.length < bookingLinkStatus.current.length);
        setBookingLinkCheckAll(statusList.length === bookingLinkStatus.current?.length);

        setCurrent(1);
    }, []);

    // patient rating filter
    const handlePatientRatingFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && patientRatingStatus ? patientRatingStatus.map((option: CheckboxOptionType) => option.value) : [];

        setPatientRatingList(checkedListData);
        setPatientRatingIndeterminate(false);
        setPatientRatingCheckAll(e.target.checked);

        setCurrent(1);
    }, [patientRatingStatus]);

    const handlePatientRatingFilter = useCallback((statusList: CheckboxValueType[]) => {
        setPatientRatingList(statusList);
        setPatientRatingIndeterminate(!!statusList.length && !!patientRatingStatus && statusList.length < patientRatingStatus.length);
        setPatientRatingCheckAll(statusList.length === patientRatingStatus?.length);

        setCurrent(1);
    }, [patientRatingStatus]);

    // provider mis status filter
    const handleMicStatusFilterCheckAll = useCallback((e: CheckboxChangeEvent) => {
        const checkedListData = e.target.checked && providerMicStatus ? providerMicStatus.map((option: CheckboxOptionType) => option.value) : [];

        setMicStatusList(checkedListData);
        setMicStatusIndeterminate(false);
        setMicStatusCheckAll(e.target.checked);

        setCurrent(1);
    }, [providerMicStatus]);

    const handleMicStatusFilter = useCallback((statusList: CheckboxValueType[]) => {
        setMicStatusList(statusList);
        setMicStatusIndeterminate(!!statusList.length && !!providerMicStatus && statusList.length < providerMicStatus.length);
        setMicStatusCheckAll(statusList.length === providerMicStatus?.length);

        setCurrent(1);
    }, [providerMicStatus]);

    const filterNameDebounce = useRef(debounce((setFn, value) => {
        setFn(value);
        setCurrent(1);
    }, 500));

    const filterUidDebounce = useRef(debounce((setFn, value) => {
        setFn(value);
        setCurrent(1);
    }, 500));

    const handleShowMappingModal = useCallback((row: IBPMappingItem) => {
        if (editPermission) {
            handleSetCurrentRow(row);
            showMappingModal();
        }
    }, [handleSetCurrentRow, showMappingModal, editPermission]);

    const handleShowSlugModal = useCallback((row: IBPMappingItem) => {
        if (editPermission) {
            handleSetCurrentRow(row);
            showSlugModal();
        }
    }, [showSlugModal, handleSetCurrentRow, editPermission]);

    const handleShowPatientRatingModal = useCallback((row: IBPMappingItem) => {
        if (ratingPermission) {
            handleSetCurrentRow(row);
            showPatientRatingModal();
        }
    }, [showPatientRatingModal, handleSetCurrentRow, ratingPermission]);

    const downloadFile = useCallback(async () => {
        try {
            const response = await exportEHRProviderList();
            if (response && !response.error && response.data) {
                const csvData = response.data;
                const fileName = `Providers_${dayjs().format('MM/DD/YYYY')}.csv`;
                downloadFileWithBlob(csvData, fileName);
            } else {
                message.error(response?.error);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const { loading: exportLoading, run: exportRun } = useRequest(downloadFile, {
        manual: true,
    });

    const handleGenerateProfileLink = useCallback(async (id: number) => {
        if (id) {
            setPageLoading(true);
            try {
                const res = await getHelloKlairtyProfileLink(id);

                if (res && !res.error) {
                    if (res.data?.data && res.data?.data.length > 1) {
                        const profileLinks = res.data?.data.map((link: string) => prereleaseOriginReplacement(link, PRE_RELEASE_ORIGIN));

                        setCurrentProfileLinks(profileLinks);
                        setShowProfileLinkModal(true);
                    } else if (res.data?.data?.[0]) {
                        const newUrl = prereleaseOriginReplacement(res.data?.data[0], PRE_RELEASE_ORIGIN);

                        window.open(newUrl, '_blank');
                    } else {
                        message.error('This provider has no profile link yet');
                    }
                } else {
                    message.error(res.error);
                }
            } catch (e) {
                console.error(e);
            }
            setPageLoading(false);
        }
    }, []);

    const handleReList = useCallback(async (item: IBPMappingItem) => {
        if (editPermission) {
            handleSetCurrentRow(item);
            try {
                const res = await findProviderByEmail(item.email, 2);

                if (res && !res.error) {
                    const providerRecords = res.data?.data?.records;

                    if (providerRecords && providerRecords.length > 0) {
                        const { mappingStatus } = providerRecords[0];

                        if (mappingStatus === EProviderMappingStatusValue.AIR_TABLE_MAPPING) {
                            showMappingConfirmModal();

                            return;
                        }
                    }
                } else {
                    message.error(res?.error);
                }

                handleListingStatusChange(item.id, EProviderListMappingStatusValue.LISTED);
                hideMappingConfirmModal();
            } catch (e) {
                console.error(e);
            }
        }
    }, [editPermission, findProviderByEmail, handleListingStatusChange]);

    const tryView = async (resourceId: string) => {
        const result = await getPrivateFilePreview(resourceId);
        if (!result.error) {
            const url = result?.data?.data?.singedUrl;
            if (url) {
                window.open(url);
            } else {
                message.error('Failed to get the file preview');
            }
        }
    };

    const handleSycnEHR = async (providerId: number) => {
        setSyncingEhrId(providerId);
        const result = await syncEhr(providerId);
        if (!result.error) {
            message.success('Sync with EHR successfully');
        }
        setSyncingEhrId(undefined);
    };

    const handleShowProgramModal = (programs: IKlarityProgram[], providerId: number) => {
        setCurrentPrograms(programs)
        setCurrentProviderId(providerId)
        setShowProgramModal(true);
    }

    const columns: ColumnsType<any> = [
        {
            title: 'NAME',
            dataIndex: 'name',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '280px',
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        // value={nameFilter}
                        onChange={(e) => {
                            const filterName = e.target.value;
                            filterNameDebounce.current(setNameFilter, filterName);
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (name: string, row: IBPMappingItem) => {
                const { firstName, lastName, email, conflict } = row;
                return (
                    <div className={s.nameWrap}>
                        {conflict && <div className={s.warning} onClick={() => handleShowMappingModal(row)} />}
                        <div className={s.name}>
                            {getProviderNameDisplay({ firstName, lastName })}
                            <div className={s.email}>
                                {email}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: 'UID',
            dataIndex: 'uidPublic',
            filterSearch: true,
            className: s.tableColumn,
            filterIcon: <SearchOutlined />,
            width: '100px',
            fixed: 'left',
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        // value={nameFilter}
                        onChange={(e) => {
                            const filterUid = e.target.value;
                            filterUidDebounce.current(setUidFilter, filterUid);
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (uid: string) => {
                return (
                    <div className={s.nameWrap}>
                        {uid}
                    </div>
                );
            },
        },
        {
            title: 'PROFILE STATUS',
            dataIndex: 'listingStatus',
            key: 'listingStatus',
            width: '150px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={listingStatusIndeterminate} onChange={handleListingStatusFilterCheckAll} checked={listingStatusCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={providerListingStatus} value={listingStatusList} onChange={handleListingStatusFilter} />
                        </Space>
                    </div>
                );
            },
            render: (status) => status && <OpsProviderMappingListStatusTag status={status} />,
        },
        {
            title: 'DATA STATUS',
            dataIndex: 'mappingStatus',
            key: 'mappingStatus',
            width: '130px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={mappingStatusIndeterminate} onChange={handleMappingStatusFilterCheckAll} checked={mappingStatusCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={providerMappingStatus} value={mappingStatusList} onChange={handleMappingStatusFilter} />
                        </Space>
                    </div>
                );
            },
            render: (status) => {
                if (status === EProviderMappingStatusValue.EHR_MAPPED) {
                    return 'Mapped';
                }

                return 'Unmapped';
            },
        },
        {
            title: 'MALPRACTICE INSURANCE',
            dataIndex: 'micStatus',
            key: 'micStatus',
            width: '220px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={micStatusIndeterminate} onChange={handleMicStatusFilterCheckAll} checked={micStatusCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={providerMicStatus} value={micStatusList} onChange={handleMicStatusFilter} />
                        </Space>
                    </div>
                );
            },
            render: (status) => {
                return status || 'None';
            },
        },
        {
            title: 'W-9 FORM',
            dataIndex: 'w9Form',
            key: 'w9Form',
            width: '220px',
            className: s.tableColumn,
            render: (w9: string) => {
                if (!w9) {
                    return <span>-</span>;
                }
                return (
                    <span
                        className={s.link}
                        onClick={() => {
                            tryView(w9);
                        }}
                    >View
                    </span>);
            },
        },
        {
            title: 'EHR SETUP',
            dataIndex: 'ehrSetUpCompleted',
            key: 'ehrSetUpCompleted',
            width: '170px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Checkbox.Group
                            options={ehrStatus.current}
                            value={ehrStatusFilter}
                            onChange={(val) => {
                                setEhrStatusFilter(val);
                                setCurrent(1);
                            }}
                        />
                    </div>
                );
            },
            render: (value) => {
                return value ? 'Complete' : 'Incomplete';
            },
        },
        {
            title: 'PROFILE LINK',
            dataIndex: 'profileLink',
            key: 'bookingLink',
            width: '170px',
            className: s.tableColumn,
            render: (v, item: IBPMappingItem) => {
                const { listingStatus } = item;
                if (listingStatus !== EProviderListMappingStatusValue.PENDING_REVIEW && listingStatus !== EProviderListMappingStatusValue.DISQUALIFIED) {
                    return <a className={s.profileLink} onClick={() => handleGenerateProfileLink(item.id)}>Link</a>;
                }

                return null;
            },
        },
        {
            title: 'BOOKING LINK',
            dataIndex: 'bookLink',
            key: 'bookingLink',
            width: '170px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={bookingLinkIndeterminate} onChange={handleBookingLinkFilterCheckAll} checked={bookingLinkCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={bookingLinkStatus.current} value={bookingLinkList} onChange={handleBookingLinkFilter} />
                        </Space>
                    </div>
                );
            },
            render: (value) => {
                if (value) {
                    return value > 1 ? `${value} packages` : `${value} package`;
                }

                return '-';
            },
        },
        {
            title: 'KLARITY PROGRAM',
            dataIndex: 'programList',
            key: 'programList',
            width: '170px',
            filters: programOptions,
            className: s.tableColumn,
            render: (value, rowData) => {
                if (value && value.length > 0) {
                    const { bpProviderId } = rowData;
                    return (
                        <div className={s.programList}>
                            {
                                value.map((item: IKlarityProgram) => {
                                    const { id, programStatus, displayStatus, programName } = item
                                    return (
                                        <div key={id} className={`${s.program}`}>
                                            <div className={`${s.status} ${programStatus === 'A' ? s.active : ''}`}></div>
                                            <div className={`${s.visibel}`}>
                                                {displayStatus === "A" ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </div>
                                            <div className={s.title}>{programName}</div>
                                        </div>
                                    )
                                })
                            }
                            {editPermission && <div className={s.editIcon} onClick={() => handleShowProgramModal(value, bpProviderId)} />}
                        </div>
                    )
                }

                return '-';
            },
        },
        {
            title: 'PATIENT RATING',
            dataIndex: 'patientRating',
            key: 'patientRating',
            width: '170px',
            className: s.tableColumn,
            filterDropdown: () => {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Checkbox indeterminate={patientRatingIndeterminate} onChange={handlePatientRatingFilterCheckAll} checked={patientRatingCheckAll}>
                                Check all
                            </Checkbox>
                            <Checkbox.Group options={patientRatingStatus} value={patientRatingList} onChange={handlePatientRatingFilter} />
                        </Space>
                    </div>
                );
            },
            render: (val: string, row: IBPMappingItem) => {
                const label = patientRatingStatus?.find((e) => val.toString() === e.value.toString())?.label || '';

                return (
                    <div className={s.slug}>{label}
                        {ratingPermission && <div className={s.editIcon} onClick={() => handleShowPatientRatingModal(row)} />}
                    </div>
                );
            },
        },
        {
            title: 'SLUG',
            dataIndex: 'providerNameSlug',
            width: '180px',
            className: s.tableColumn,
            filterSearch: true,
            filterIcon: <SearchOutlined />,
            filterDropdown: () => (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        onChange={(e) => {
                            const filterName = e.target.value;

                            filterNameDebounce.current(setSlugFilter, filterName);
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                </div>
            ),
            render: (text, row) => {
                return (
                    <div className={s.slug}>{text}
                        {editPermission && <div className={s.editIcon} onClick={() => handleShowSlugModal(row)} />}
                    </div>
                );
            },
        },
        {
            title: 'LAST MODIFIED',
            dataIndex: 'lastUpdateAt',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            width: '150px',
            className: s.tableColumn,
            render: (time) => {
                return (
                    <div>{time ? dayjs(time).format('MM/DD/YYYY') : 'Nil'}</div>
                );
            },
        },
        {
            title: 'KLARITY LAUNCH DATE',
            dataIndex: 'firstLaunchAt',
            width: '250px',
            className: s.tableColumn,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (date?: string) => (date ? dayjs(date).format('MM/DD/YYYY') : 'Not launched yet'),
        },
        {
            title: 'EHR',
            width: '100px',
            fixed: 'right',
            className: s.tableColumn,
            align: 'center',
            render: (value) => {
                const canSync = value?.bpProviderId && value?.ehrSetUpCompleted && value?.uniprofileStatus === 'approved';
                return canSync ?
                    <Button
                        loading={!!(syncingEhrId && (`${syncingEhrId}`) === (`${value.bpProviderId}`))}
                        type="text"
                        className={s.link}
                        onClick={() => {
                            handleSycnEHR(value.bpProviderId);
                        }}
                    >Sync
                    </Button>
                    : null;
            },
        },
        editPermission ? {
            title: '',
            width: '200px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item: IBPMappingItem) => {
                if (item.listingStatus === EProviderListMappingStatusValue.UNLISTED) {
                    return (
                        <div>
                            <Button
                                className={s.link}
                                onClick={() => handleReList(item)}
                                type="text"
                            >Re-list
                            </Button>
                        </div>
                    );
                }
                if (item.listingStatus === EProviderListMappingStatusValue.PAUSE_LISTING && item.hasBookingLink) {
                    return (
                        <div>
                            <Button
                                className={s.link}
                                onClick={() => handleReList(item)}
                                type="text"
                            >Re-list
                            </Button>
                        </div>
                    );
                }

                if (item.listingStatus === EProviderListMappingStatusValue.LISTED) {
                    return (
                        <div>
                            <Button
                                className={s.link}
                                onClick={() => {
                                    if (editPermission) {
                                        handleListingStatusChange(item.id, EProviderListMappingStatusValue.UNLISTED);
                                    }
                                }}
                                style={{
                                    color: '#C45407'
                                }}
                                type="text"
                            >Delete Listing (Danger)
                            </Button>
                        </div>
                    );
                }
            },
        } : {},
        {
            title: '',
            width: '100px',
            className: s.tableColumn,
            fixed: 'right',
            render: (item: IBPMappingItem) => {
                const { conflict, listingStatus, mappingStatus } = item;
                if (item.listingStatus !== EProviderListMappingStatusValue.UNIPROFILE_NEEDED) {
                    return (
                        <div>
                            <Button
                                className={s.link}
                                onClick={() => {
                                    navigate(`/${PATH.CUSTOMIZE_PROVIDER_PROFILE_PAGE}?id=${item.id}&providerId=${item.bpProviderId}&slug=${encodeURIComponent(item.providerNameSlug)}&conflict=${!!conflict}&profileStatus=${listingStatus}&mappingStatus=${mappingStatus}`);
                                }}
                                type="text"
                            >View
                            </Button>
                        </div>
                    );
                }
            },
        },
    ];

    const handleProgramDisplayChange = useCallback(async (id: number, checked: boolean) => {
        if (currentProviderId) {
            setDisplayLoading(true)

            try {
                const res = await updateProgramDisplayStatus(id, currentProviderId, checked ? 'ACTIVE' : 'INACTIVE')

                if (res && !res.error) {
                    setCurrentPrograms((prev) => {
                        return prev.map((program) => {
                            if (program.id === id) {
                                return {
                                    ...program,
                                    displayStatus: checked ? 'A' : 'D'
                                }
                            }
                            return program
                        })
                    })
                } else {
                    message.error(res?.error)
                }
            } catch (e) {
                console.error(e)
            }

            setDisplayLoading(false)
        }
    }, [currentProviderId]);

    return (
        <Spin spinning={pageLoading || programLoading}>
            <div className={s.wrap}>
                <Modal
                    title="Klarity program"
                    open={showProgramModal}
                    onCancel={() => {
                        setShowProgramModal(false)
                        setCurrentPrograms([])
                        setCurrentProviderId(undefined)
                    }}
                    onOk={() => {
                        setShowProgramModal(false)
                        setCurrentPrograms([])
                        setCurrentProviderId(undefined)
                        handleRefetch()
                    }}
                    okText="Save"
                >
                    {
                        <div className={s.editingPromgamList}>
                            {
                                currentPrograms.map((program) => {
                                    const { id, programStatus, displayStatus, programName } = program
                                    return (
                                        <div key={id} className={s.editingProgram}>
                                            <div className={s.nameBox}>
                                                <div className={`${s.status} ${programStatus === 'A' ? s.active : ''}`}></div>
                                                <div className={s.text}>{programName}</div>
                                            </div>
                                            {
                                                programStatus === "A" ?
                                                    <div className={s.actionBox}>
                                                        <Switch checked={displayStatus === "A"} loading={displayLoading} onChange={(e: boolean) => handleProgramDisplayChange(id, e)} />
                                                        Display
                                                    </div> :
                                                    <div className={s.disabled}>
                                                        Set to 'Active' before display
                                                    </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                </Modal>
                <MulitProfileLinkModal
                    show={showProfileLinkModal}
                    onCancel={() => setShowProfileLinkModal(false)}
                    onOk={() => setShowProfileLinkModal(false)}
                    profileLinks={currentProfileLinks}
                />
                <div className={s.title}>
                    <div className={s.left}>Kiwi Health providers</div>
                    <div className={s.right}>
                        <Button icon={<DownloadOutlined />} onClick={exportRun} loading={exportLoading}>Download all</Button>
                    </div>
                </div>
                <CommonTable<any>
                    rowKey={(record: any) => record.id}
                    bordered
                    // loading={loading}
                    columns={columns}
                    data={records || []}
                    onChange={handleTableChange}
                    pagination={{
                        current,
                        pageSize,
                        total,
                    }}
                    loading={tableLoading}
                    scroll={{ x: '1200' }}
                // rowSelection={rowSelection}
                />
            </div>
        </Spin>
    );
};

export default HelloKlarityTable;
